<template>
    <div v-if="!loader">
        <b-card class="row-height" no-body>
            <div>
                <b-card no-body class="card-statistics" style="">
                    <b-card-body class="statistics-body">
                        <h2 class="text-purple font-weight-bold mb-1">
                            {{ $t("partner_details") }}
                        </h2>
                        <br>

                        <b-row>
                            <b-col>
                                <span>Commission: </span>
                                <span>{{ partner.discount }}%</span>
                            </b-col>
                            <b-col>
                                <span>member discount: </span>
                                <span>{{ partner.percentage }}%</span>
                            </b-col>
                            <b-col>
                                <span>Aequitex Fee: </span>
                                <span>{{ partner.hive_fee_percent }}%</span>
                            </b-col>
                        </b-row>

                        <br>
                        <div>
                            <b-form>
                                <b-row>
                                    <b-col md="4">
                                        <b-form-group :label="$t('start_date')">
                                            <b-form-datepicker v-model="start_date"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                        <b-form-group :label="$t('end_date')">
                                            <b-form-datepicker v-model="end_date"></b-form-datepicker>
                                        </b-form-group>

                                    </b-col>
                                    <b-col md="4">
                                        <b-form-group :label="$t('currency')">
                                            <b-form-input v-model.trim="currency_search"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="4">
                                        <b-form-group :label="$t('company')">
                                            <b-form-input v-model.trim="company_search"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                        <b-form-group :label="$t('invoice')">
                                            <b-form-input v-model.trim="invoice_search"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                        <b-form-group :label="$t('type')">
                                            <b-form-select v-model="type_search">
                                                <option value="">{{$t('choose')}}</option>
                                                <option value="annual_fee">Annual Fee</option>
                                                <option value="aequitex_fee">Aequitex Fee</option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="2">
                                        <b-form-group label="" class="my-1 py-1">
                                            <b-button @click="getData()" variant="primary" size="md">{{$t('search')}}
                                            </b-button>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="2">
                                        <b-form-group label="" class="my-1 py-1">
                                            <b-button class="d-flex" @click="downloadPdf()" variant="primary" size="md">
                                                <FeatherIcon size="12" icon="DownloadIcon"></FeatherIcon>
                                                PDF
                                            </b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                            <b-table hover :items="reportData" :fields="visibleFields" responsive>
                                <template #head()="scope">
                                    <div class="text-nowrap">{{ $t(scope.label) }}</div>
                                </template>
                                <template #cell(invoice_id)="data">
                                    <span v-if="data.item.invoice_id">I-{{data.item.invoice_id}}</span>
                                    <span v-else>-</span>
                                </template>
                                <template #cell(company_id)="data">
                                    <span v-if="data.item.company_id">C-{{data.item.company_id}}</span>
                                    <span v-else>-</span>
                                </template>
                                <template #cell(currency_id)="data">
                                    <span v-if="data.item.currency">{{data.item.currency.name}}</span>
                                    <span v-else>-</span>
                                </template>
                                <template #cell(amount)="data">
                                    <span v-if="data.item.amount">{{amountFormat(data.item.amount, data.item.currency.code)}}</span>
                                    <span v-else>-</span>
                                </template>
                                <template #cell(created_at)="data">
                                    <span v-if="data.item.created_at">{{data.item.created_at.substring(10,0)}}</span>
                                    <span v-else>-</span>
                                </template>

                            </b-table>
                            <div class="col-12 row alert alert-secondary p-1" v-for="(total, currency) in calculateTotal()" :key="currency">
                                <strong>{{ $t("total") }} {{ currency }}: </strong>
                                <p class="ml-auto" v-if="total">{{ amountFormat(total, currency) }}</p>
                                <p class="ml-auto" v-else> 0.00 {{ currency }}</p>
                            </div>
                        </div>
                        <div>
                        </div>
                    </b-card-body>
                </b-card>
            </div>
        </b-card>
    </div>
    <div v-else><img src="/new-loader.svg"/></div>
</template>
<script>
  import {ValidationProvider, ValidationObserver} from "vee-validate";
  import Table from "@/components/Common/Table2";
  import fileDownload from "js-file-download";
  import {CurrencyAmountFormat} from '@core/utils/CurrencyAmountFormat';

  import i18n from "@/libs/i18n";
  import {forEach} from "postcss-rtl/lib/affected-props";

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      Table
    },
    data() {
      return {
        partner: null,
        commission: null,
        member_discount: null,
        hive_fee: null,
        show_username: true,
        total: 0,
        company: null,
        billing: null,
        start_date: null,
        end_date: null,
        loader: false,
        preregistered: false,
        reportFields: [
          {
            label: "invoice_id",
            align: "start",
            key: "invoice_id",
            visible: true
          },
          // {
          //   label: "username",
          //   key: "username",
          //   sortable: false,
          //   visible: true
          // },
          {
            label: "company_id",
            align: "start",
            key: "company_id",
            sortable: false,
            visible: true
          },
          {
            label: "type",
            align: "start",
            key: "type",
            sortable: false,
            visible: true
          },

          {
            label: "currency",
            align: "start",
            key: "currency_id",
            sortable: false,
            visible: true
          },
          {
            label: "amount",
            align: "start",
            key: "amount",
            sortable: false,
            visible: true
          },
          {
            label: "date",
            align: "start",
            key: "created_at",
            sortable: false,
            visible: true
          },

        ],

        reportData: null,
      };
    },
    computed: {
      visibleFields() {
        return this.reportFields.filter(field => field.visible)
      }
    },
    created() {
      // this.start_date = new Date();
      // this.end_date = new Date(this.start_date);
      // this.end_date.setDate(this.start_date.getDate() + 1);
      // this.start_date.getDate();
      this.getData();
      this.getPartner();

    },
    methods: {

      getPartner() {
        this.$http
            .post(`/get-partner/${this.$route.params.id}`)
            .then((response) => {
              console.log(response);
              this.partner = response.data.value
            })
            .catch((error) => {})
      },

      searchReport() {
      },

      amountFormat(amount, currency) {
        return CurrencyAmountFormat(amount, currency)
      },

      // showUsername() {
      //   if (this.show_username) {
      //     this.reportFields[1].visible = true;
      //   } else {
      //     this.reportFields[1].visible = false;
      //   }
      // },
      async downloadPdf(item) {

        try {
          const response = await this.$http
              .post(
                  `/partners/${this.$route.params.id}/pdf`, {
                    start_date: this.start_date,
                    end_date: this.end_date,
                    // show_username: this.show_username
                    currency_search: this.currency_search,
                    company_search: this.company_search,
                    invoice_search: this.invoice_search,
                    type_search: this.type_search,
                  },
                  {
                    responseType: "blob",
                  }
              );

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'partner report.csv');  // Имя файла для скачивания
          document.body.appendChild(link);
          link.click();  // Имитируем клик по ссылке для начала скачивания
          link.remove(); // Убираем ссылку после использования

        }catch (error) {
          console.error('Ошибка при скачивании файла:', error);
        }


      },
      getData() {
        this.total = 0;
        this.$http
            .post(`/partners/${this.$route.params.id}`, {
              start_date: this.start_date,
              end_date: this.end_date,
              // show_username: this.show_username
              currency_search: this.currency_search,
              company_search: this.company_search,
              invoice_search: this.invoice_search,
              type_search: this.type_search,
            })
            .then((res) => {
              if (res) {
                this.reportData = res.data.value;
                this.calculateTotal();
              }
            });
      },
      calculateTotal() {
        // Используем объект для суммирования
        const sums = {};

        this.reportData.forEach((obj) => {

          console.log(obj)

          if (sums[obj.currency.code]) {
            sums[obj.currency.code] += obj.amount * 1;
          } else {
            sums[obj.currency.code] = obj.amount * 1;
          }
        });

        return sums;
      },
      showSuccessMessage(message, type) {
        let text = this.$t(message);
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      },
    },
  };
</script>
